const baseApi = '/rest';
const baseHub = process.env.REACT_APP_HUB_ADDRESS || 'http://192.168.4.1:8081';
const baseOffline = 'http://smarthome-offline-setup.propify.com';

export const isOfflineApi = () => {
  return window.location.origin === baseOffline;
};

const api = {
  UNIT: `${baseApi}/unit`,
  UNITS: `${baseApi}/units`,
  SMART_HOME_SYSTEMS_URL: `${isOfflineApi() ? baseOffline : baseApi}/systems`,
};

export const hubApi = {
  REGISTER: `${baseHub}/register`,
};

export default api;
