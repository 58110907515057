export enum PageURL {
  SPLASH = '/splash',
  SELECT_UNIT = '/select-unit',
  DEVICES = '/devices',
  LOCK_BACK_COVER = '/devices/lock-back-cover',
  SUPPORTED_DEVICES = '/devices/supported-devices',
  THERMOSTAT_INCLUSION = '/devices/thermostat-inclusion',
  THERMOSTAT_EXCLUSION = '/devices/thermostat-exclusion',
  LOCK_INCLUSION = '/devices/lock-inclusion',
  LOCK_EXCLUSION = '/devices/lock-exclusion',
  LOCK_TROUBLESHOOT = '/devices/lock-troubleshoot',
  CONTACT_MAINTENANCE = '/contact-maintenance',
}
