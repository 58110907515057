import useRouter from '@/routes';
import { MuiThemeProvider } from '@material-ui/core';
import { RouterProvider } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiTheme } from './theme';

const App = () => {
  const { router } = useRouter();

  return (
    <MuiThemeProvider theme={MuiTheme}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <RouterProvider router={router} />
    </MuiThemeProvider>
  );
};

export default App;
